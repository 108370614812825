// 培训中心
<template>
    <div class="training_center">
        培训中心
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    created() {},
    methods: {},
    computed: {},
    components: {},
};
</script>
<style lang="scss" scoped>
.training_center {
}
</style>